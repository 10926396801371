import { ref } from "@vue/reactivity";
import { FETCH_GROUPS, FETCH_USERS, RESERVATION_POLITIC } from "@/store/politics";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed, reactive } from "vue";
import moment from "moment";

export function getReservationPolitic() {

  const route = useRoute();
  const store = useStore();

  const loading = ref(false);
  const selectsLoading = ref(false);
  const reservation = ref(null);

  const selectsUsers = reactive({
    loading: false,
    selects: [],
  });

  const selectsGroups = reactive({
    loading: false,
    selects: [],
  });

  const selectBlocking = ref([
    {
      id: 1001,
      name: '1 неделя',
      value: 60 * 24 * 7,
    },
    {
      id: 1002,
      name: ' 1 месяц',
      value: 60 * 24 * 30,  //moment().daysInMonth(), //TODO; фиксируем месяц;
    },
    {
      id: 1003,
      name: '6 месяцев',
      value: 60 * 24 * 30 * 6, //moment().daysInMonth() * 6,
    },
    {
      id: 1004,
      name: 'Навсегда',
      value: 0,
    },
    {
      id: 1005,
      name: 'Блокировка запрещена',
      value: -1,
    },

  ]);

  const columnsIntervals = [
    {
      title: 'Наименование интервала',
      dataIndex: 'name',
      key: 'name',
      width: '360px',
    },
    {
      title: 'Длительность интервала',
      slots: { customRender: 'intervals' },
    },
  ];
  const dataIntervals = reactive([
    {
      id: 1001,
      name: 'Интервал бронирования',
      input: 'intervalMinutes',
    },
    {
      id: 1002,
      name: 'Интервал между бронированиями',
      input: 'breakMinutes',
    },
    {
      id: 1003,
      name: 'Автоматический сброс бронирования',
      input: 'autoCancelMinutes',
    }
  ]);

  const columnsReservationDays = [
    {
      title: 'Доступные дни бронирования',
      dataIndex: 'dayName',
      key: 'dayName',
      width: '360px',
      slots: { customRender: 'dayName' },
    },
    {
      title: 'Время начала',
      width: '250px',
      slots: { customRender: 'timeStart' },
    },
    {
      title: 'Время окончания',
      width: '250px',
      slots: { customRender: 'timeEnd' },
    },
  ];

  const dayTemplate = {
    selected: false,
    timeStart: null,
    timeEnd: null,
    timeStartValid: true,
    timeEndValid: true,
  };

  const dataReservationDays = reactive([
    {
      id: 1001,
      dayName: 'Все дни',
      option: 'all', //
      day: null,
      ...dayTemplate,
    },
    {
      id: 1002,
      dayName: 'Только будние дни',
      option: 'week', //
      day: null,
      ...dayTemplate,
    },
    {
      id: 1003,
      dayName: 'Понедельник',
      option: null,
      day: 1,
      ...dayTemplate,
    },
    {
      id: 1004,
      dayName: 'Вторник',
      option: null,
      day: 2,
      ...dayTemplate,
    },
    {
      id: 1005,
      dayName: 'Среда',
      option: null,
      day: 3,
      ...dayTemplate,
    },
    {
      id: 1006,
      dayName: 'Четверг',
      option: null,
      day: 4,
      ...dayTemplate,
    },
    {
      id: 1007,
      dayName: 'Пятница',
      option: null,
      day: 5,
      ...dayTemplate,
    },
    {
      id: 1008,
      dayName: 'Суббота',
      option: 'output',
      day: 6,
      ...dayTemplate,
    },
    {
      id: 1009,
      dayName: 'Воскресенье',
      option: 'output',
      day: 0,
      ...dayTemplate,
    },
  ]);
  const isId = computed(() => route.params?.id)

  const date = value => {
    return moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
  };

  const delaySelects = ref(1000);
  const currentTimeoutSelects = ref(undefined);

  const numberPadStart = ((value) => {
    if (value) {
      return `${value}`.padStart(2, 0);
    }
    return null;
  })

  // Сброс таймер;
  function cancelSelectsTimeout() {
    clearTimeout(currentTimeoutSelects.value);
    currentTimeoutSelects.value = undefined;
  }

  async function fetchData(id) {
    const sId = id ? id : isId.value;
    console.log('RESERVATION_POLITIC', sId)
    try {
      loading.value = true;
      const res = await store.dispatch(RESERVATION_POLITIC, sId);
      reservation.value = res;
      return res;
    } catch (e) {
      loading.value = false;
      throw e
    } finally {
      loading.value = false;
    }
  }

  // Поиск по юзерам;
  async function handleUsersSearch(search) {
    selectsUsers.loading = true;
    cancelSelectsTimeout();
    currentTimeoutSelects.value = setTimeout(async () => {
      try {
        const params = { search }
        await fetchUsers(params);
        cancelSelectsTimeout();
      } catch (e) {
        selectsUsers.loading = false;
      } finally {
        selectsUsers.loading = false;
      }
    }, delaySelects.value);
  }

  async function handleGroupsSearch(search) {
    selectsGroups.loading = true;
    cancelSelectsTimeout();
    currentTimeoutSelects.value = setTimeout(async () => {
      try {
        const params = { search }
        await fetchGroups(params);
        cancelSelectsTimeout();
      } catch (e) {
        selectsGroups.loading = false;
      } finally {
        selectsGroups.loading = false;
      }
    }, delaySelects.value);
  }

  // Получить юзе;
  async function fetchUsers(params = {}) {
    try {
      selectsUsers.loading = true;
      const res = await store.dispatch(FETCH_USERS, params);
      selectsUsers.selects = res.map(({ id, firstName, lastName, attributes }) => ({
        id,
        name: [lastName, firstName, attributes?.middleName].join(' ')
      }), []);
      return res;
    } catch (e) {
      selectsUsers.loading = false;
      throw e
    } finally {
      selectsUsers.loading = false;
    }
  }

  async function fetchGroups(params = {}) {
    try {
      selectsGroups.loading = true;
      const res = await store.dispatch(FETCH_GROUPS, params);
      if (res) {
        selectsGroups.selects = res.data.map(({ id, name, isMain }) => ({ id, name, isMain }), []);
      }
      return res;
    } catch (e) {
      selectsGroups.loading = false;
      throw e
    } finally {
      selectsGroups.loading = false;
    }
  }

  return {
    fetchData,
    fetchUsers,
    fetchGroups,
    date,
    columnsIntervals, dataIntervals, columnsReservationDays, dataReservationDays,
    handleUsersSearch, handleGroupsSearch,
    numberPadStart,
    reservation,
    loading,
    selectsLoading,
    selectsUsers,
    selectsGroups,
    isId,
    selectBlocking,
  }
}

